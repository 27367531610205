import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    CheckboxInput,
    MultiSelectInput,
    TextInput,
    Heading3,
} from '@awarego/awarego-components';
import { Box, Checkbox, Chip, MenuItem } from '@mui/material';

function ReportSetupFormFragment({
    reportsForm,
    lists,
    inputHasError,
    editingReport,
}) {
    const getListName = (id) => {
        const list = lists.find((item) => item.id === id);

        return list ? list.name : '';
    };

    useEffect(() => {
        if (editingReport) {
            if (editingReport.id) {
                reportsForm.$('id').set(editingReport.id);
            }
            reportsForm.$('name').set(editingReport.name);
            if (editingReport.selectedUserListsIds) {
                reportsForm
                    .$('employeeLists')
                    .set(editingReport.selectedUserListsIds);
            }
            if (editingReport.allUserLists) {
                reportsForm.$('allEmployees').set('value', true);
                reportsForm.$('employeeLists').set('disabled', true);
            } else {
                reportsForm.$('allEmployees').set('value', false);
                reportsForm.$('employeeLists').set('disabled', false);
            }
            reportsForm.validate();
        } else {
            reportsForm.$('employeeLists').set('disabled', false);
        }
    }, []);

    return (
        <Fragment>
            <Heading3 mb={24} displayBlock>
                Report setup
            </Heading3>
            <TextInput
                error={inputHasError('name')}
                helperText={
                    inputHasError('name')
                        ? 'Please enter name for the report'
                        : undefined
                }
                stretched
                {...reportsForm.$('name').bind()}
            />
            <TextInput
                select
                //stretched
                {...reportsForm.$('reportType').bind()}
            >
                {reportsForm.$('reportType').extra.map((t) => {
                    return (
                        <MenuItem key={t.value} value={t.value}>
                            {t.label}
                        </MenuItem>
                    );
                })}
            </TextInput>
            {reportsForm.$('reportType').value !== 'weak-employees' && (
                <>
                    <MultiSelectInput
                        error={inputHasError('employeeLists')}
                        helperText={
                            inputHasError('employeeLists')
                                ? 'Please select at least one employee list'
                                : undefined
                        }
                        inline
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em>'Select from existing lists'</em>;
                            } else {
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: 0.5,
                                        }}
                                    >
                                        {selected &&
                                            selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={getListName(value)}
                                                    disabled={
                                                        reportsForm.$(
                                                            'allEmployees'
                                                        ).value
                                                    }
                                                />
                                            ))}
                                    </Box>
                                );
                            }
                        }}
                        {...reportsForm.$('employeeLists').bind()}
                    >
                        {lists
                            .sort((a, b) => a.name - b.name)
                            .map((list) => (
                                <MenuItem
                                    disableRipple
                                    key={list.id}
                                    value={list.id}
                                    style={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                    }}
                                >
                                    <Checkbox
                                        checked={
                                            reportsForm
                                                .$('employeeLists')
                                                .bind()
                                                .value.indexOf(list.id) > -1
                                        }
                                        disableRipple
                                        color="primary"
                                    />
                                    {list.name}
                                </MenuItem>
                            ))}
                    </MultiSelectInput>
                    <CheckboxInput
                        disableRipple
                        error={inputHasError('allEmployees')}
                        {...reportsForm.$('allEmployees').bind()}
                    />
                </>
            )}
        </Fragment>
    );
}

export default observer(ReportSetupFormFragment);
