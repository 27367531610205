import MobxReactForm from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import { fieldDefinitions, ruleDefinitions } from '../ReportFormFieldDefs';

const rules = {
    multipleEmails: ruleDefinitions.multipleEmails,
    requiredUnless: ruleDefinitions.requiredUnless,
    requiredIfEmptyArray: ruleDefinitions.requiredIfEmptyArray,
};

const plugins = {
    dvr: dvr({
        package: validatorjs,
        extend: ({ validator, form }) => {
            // here we can access the `validatorjs` instance (validator)
            // and we can add the rules using the `register()` method.
            Object.keys(rules).forEach((key) =>
                validator.register(key, rules[key].function, rules[key].message)
            );
        },
    }),
};

//const plugins = { dvr: dvr(validatorjs) };

const fields = {
    id: fieldDefinitions.id,
    name: fieldDefinitions.name,
    employeeLists: fieldDefinitions.employeeLists,
    allEmployees: fieldDefinitions.allEmployees,
    scheduleFrequency: fieldDefinitions.scheduleFrequency,
    scheduleWeekday: fieldDefinitions.scheduleWeekday,
    scheduleHour: fieldDefinitions.scheduleHour,
    scheduleWeekdayPosition: fieldDefinitions.scheduleWeekdayPosition,
    nextReportSentAtText: fieldDefinitions.nextReportSentAtText,

    reportType: fieldDefinitions.reportType,
    recipientsEmails: fieldDefinitions.recipientsEmails,
    customEmailSubject: fieldDefinitions.customEmailSubject,
};

const options = {
    showErrorsOnReset: false,
};

class ReportRecurringFormDef extends MobxReactForm {
    constructor(setup, hooks) {
        super({ fields, ...setup }, { plugins, hooks, options });
    }
}

export default ReportRecurringFormDef;
